import React, { PureComponent } from "react";
import CommonHeader from "../common/landing/Header/common-header";
import CommonFooter from "../common/landing/Footer/common-footer";
import Contact from "../common/landing/contact";
import AOS from 'aos';
import 'aos/dist/aos.css';
import MailChimpForm from "./MailChimp";
import { Link} from 'react-router-dom';
import MainCarousel from "../common/CardCarousel/MainCarousel";
const dynamicCardsClass= "col-lg-4 col-md-3 mb-4 mb-md-0 col-wd-2 text-center";
 
class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formData: {},
      sender_name: "",
      focus: false,
      demo:false
    };
    this.handleChange = this.handleChange.bind(this);
    AOS.init({
      once:true
    });
  }


  refreshPage() {
    window.location.reload(false);
  }


  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
   
  
  render() {
    return (
      <>
        <div className="home-page-conatiner landing-page-main-class">
          {/* <CommonHeader scrollDemo ={this.scrolltoContactDemo} scrollContact ={this.scrolltoContact} showbtns={true}/> */}
          <div className="landing-page-conatiner">
            <section className="section-1 black-bg-section">
              <div className="container lp-main-container-2">
                <div className="row py-35">
                  <div className="col-lg-6 col-sm-12 pt-110">
                    <div className="max-width">
                      <h1 className="Site-Heading mb-1" data-aos="fade-up" data-aos-duration="1250">Data Asset Builder</h1>
                    
                    <p className="font-fff section-1-title px-4special" data-aos="fade-up" data-aos-duration="1250">
                    Most Advanced PEP Data Asset focused machine learning and automation platform for large teams
                    </p>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                      {/* <ul className="list-items font-fff" data-aos="fade-up" data-aos-duration="1250">
                      <li className="my-2 font-fff" data-aos="fade-up" data-aos-duration="1250">Enabling the automation of certain components of the research process </li>
                      <li className="my-2 font-fff" data-aos="fade-up" data-aos-duration="1250">Establishing a workflow platform for the research/editorial team</li>
                      <li className="my-2 font-fff" data-aos="fade-up" data-aos-duration="1250">Ensuring ongoing enhancements via the implementation of analytics, algorithms, and data science</li>
                    </ul> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-3  black-border"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book Discovery Call 
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3 mr-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Free Demo 
                    </button>
                    </div>
                  </div>
                 
                  <div className="col-lg-6 col-sm-12 img-div my-2 text-center">
                  <object data={require("../../assets/Landing page icons/DAB V1.svg")}></object>
                    </div>
                </div>
              </div>
            </section>
            <section className="section-2">
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                
                  <div className="container lp-main-container">
                <div className="row row-mt pt-80">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose text-center">
                    Features
                    </h1>
                    <p>
                    Data Asset Builder is SaaS enterprise platform designed to highlight third party AML and other business risks via quick scan to deliver best-in-class Turn around time and case outcomes. 

                    </p>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-01.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      AI & ML Powered
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      ML driven real time global news checks
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Dynamic Workflow
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Ensures dynamic workflow across the teams 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-03.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Flexibility
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Link entities, stories, events etc. via a visual association map
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-04.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Highly Scalable
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Highly scalable platform which can be easily expanded/ upgraded on demand 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-05.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Easy Integration 
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Ensures easy API Integrations 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-06.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      User Friendly
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Platform with a user friendly interface
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
            {/* <section className="section-2">
              <div className="container lp-main-container-2 pt-80 pb-80" data-aos="fade-up" data-aos-duration="1250">
               <MainCarousel />
              </div>
            </section> */}
            {/* <section className="section-2 section-grey">
              <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-80">
                  <div className="col-md-7 col-sm-12"> <img className="local-img" src={require("../../assets/Landing page icons/Frame 897.png")}/></div> 
                  <div className="col-md-5 col-sm-12 text-left "> <div className="purpose mt-5">
                    <h1 className="Made-For-a-Purpose p-0 mb-3">
                      Benefits
                    </h1>
                    <div className="p-0">
                    It enables the team to:
                    <ul className="list-items">
                      <li className="my-2">Consume massive & aggregated - information, news and content </li>
                      <li className="my-2"> Monitor organizations or focus areas, continuously </li>
                      <li className="my-2">Make critical business decisions </li>
                    </ul>
                    </div>
                  </div></div>
                 
                </div>
             
              </div>
            </section>
            <section className="section-2 purple-background text-justify">

              <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-80">
                <div className="col-md-5 col-sm-12 text-left "> <div className="purpose mt-5">
                    <h1 className="Made-For-a-Purpose p-0">
                      Work across <br/> multiple Teams
                    </h1>
                    <p className="p-0">
                    Structured workflow built to enable dynamic and seamless research & reporting for the user and collaboration among team members.

                    </p>
                  </div></div>
                  <div className="col-md-7 col-sm-12"> <img className="local-img" src={require("../../assets/Landing page icons/Frame 896.png")}/></div> 
              
                 
                </div>
             
              </div>
            </section> */}
            <section className="section-2 section-grey">
              <div className="container lp-main-container" data-aos="fade-up" data-aos-duration="1250">
                
                  <div className="container lp-main-container">
                <div className="row row-mt pt-80">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose text-center">
                    Benefits
                    </h1>
                    <p className="">
                    Some of the in-built features and practices that enabled us to improve both quantity and quality of delivery.

                    </p>
                  </div>
                </div>
                <div className="row row-mt pt-80">
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-01.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                        Data Enrichment
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Identification ,extraction of relevant data to enrich and enhance new and existing PEP profiles 
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-03.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                        Productivity and Performance 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Better productivity with defined KPIs, workflows, data enrichment, listing rules, master structures, error analytics and performance forecasting
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-05.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Integrations
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Integration with multiple external services such as multiple search engines, external dashboards, and translations
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-04.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Communication
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Real-time updates on the status of the requests made and the project for seamless communication
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Delivery 
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      API delivery of profiles and data. Delivery is also done through .xml files
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12  mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/Data Asset Page Icons-06.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Trusted Sources 
                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Sources are categorized based on multiple inputs, features, reputation features, past usage and rule-based identification for data extraction
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </section>
            <section className="section-2 text-justify"> 
                 
                <div className="container lp-main-container-2" data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="col-lg-6 col-sm-12  text-center d-flex align-tems-center">
                  <object data={require("../../assets/Landing page icons/DAB V2.svg")}></object>
                  </div>
                  <div className="col-lg-6 col-sm-12 pt-50 ">
                    <div className="max-width px-3" >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="fontrandom text-left" >
                       Still have question?<br/><span> Connect </span>with us
                    </h1>
                    <div className="my-3">
                      <div className="d-flex listGroup mb-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div className=" text-left">
                          <h5 className="listItem">Explore our Technology and Data Asset Solutions</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup my-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Get Pricing Information</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup mt-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Free Trial or Demo</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                    //  to={{pathname:'/contact-us',
                    //  state:{
                    //   Subject:"Prescreening Solution-Book a Discovery Call"
                    //  }}}
                    //   type="button"
                      className="btn btn-primary sm-primary sm-primary-1  trialButton my-3 mr-4"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book Discovery Call 
                    </button>
                    <button
                      // to={{pathname:'/contact-us',
                      // state:{
                      //  Subject:"Prescreening.io - Request Demo"
                      // }}}
                      // type="button"
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Free Demo 
                    </button>
                    </div>
                    </div>
                </div>
              </div>
            </section>
            
            <section className="p-0">
              <div className="section-grey" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true" data-aos-offset="300">
                <div className="lp-main-container-2 container ">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h2 className="WeeklyNewsletter">Subscribe to our weekly newsletter</h2>
                 </div>
                 <div className="col-md-7 col-sm-12">
                  <MailChimpForm/>
                 </div>
                 </div>
              </div>
              </div>
            </section>
          </div>
          {/* <CommonFooter /> */}
        </div>
      </>
    );
  }
}

export default LandingPage;
