import React, { PureComponent } from 'react';
import CommonFooter from '../common/landing/Footer/common-footer';
import Contact from '../common/landing/contact';



class ContactUsPage extends PureComponent {
  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount(){}


  render() {

    return (
      <>

      <div className="home-page-conatiner">
        {/* start page title section */}
         <div className="container main-container">
            <div className="row">
               <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2">
                <h1>Contact Us</h1>
               </div>
               <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                  <p className="font-color-grey font-size-14">
                  Interested in learning more about AML Penalties? 
                  Reach out to us with your queries, feedback or complaints. 
                 </p>
                 <p className="font-color-grey font-size-14">
                   If you wish to send a mail to us please write us on contact@amlpenalties.com
                 </p>
                </div>
               {/*<div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                 <a className="btn btn-primary btn-lg-white float-r">VIEW API DOCUMENTATION</a>
               </div> */}
            </div>
            {/* end page title section */}

            {/* contact section */}
            <div className="row row-mt pt-80">
                {/*<div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                 <div className="contact-form">
                    <form>
                      <div class="form-group">
                        <label for="email">Full Name</label>
                        <input type="text" class="form-control" id="" placeholder="Full name" />
                      </div>
                      <div class="form-group">
                        <label for="email">Your Email Address</label>
                        <input type="email" class="form-control" id="email" placeholder="abv@gmail.com" />
                      </div>
                      <div class="form-group">
                        <label for="pwd">Subject</label>
                        <input type="text" class="form-control" id="pwd" placeholder="Question about your article" />
                      </div>
                      <div class="form-group">
                        <label for="pwd">Message</label>
                        <textarea type="text" rows="5" class="form-control" id="pwd" placeholder="Your message start with..."></textarea>
                      </div>
                      <button type="submit" class="contact-btn">Send a Message</button>
                    </form>
                  </div>
               </div>
               <div className="col-lg-6 col-md-6 mb-4 mb-md-0 col-wd-2">
                <img src="" />
               </div> */}
                <Contact pageName="contact-us"/>
            </div>
            {/* contact section */}


        </div>
     
     </div>

      {/*<div className="main-box home-container root-conatiner">
      <CommonHeader /> */}

       {/* start page title section */}
       {/*<div className="api-box">
         <section className="wow fadeIn bg-light-gray padding-50px-tb page-title-small top-space api-header" style={{background:"url(" + bg + ")"}}>
             <div className="container">
                 <div className="row align-items-center">
                     <div className="col-xl-8 col-md-6 text-center text-md-left">
                         <h1 className="Sectionhead  alt-font text-extra-dark-gray mb-0">Contact Us</h1>
                     </div>
                 </div>
             </div>
         </section>
        </div> */}
        {/* end page title section */}

        {/* api section */}
         {/*<div className="common-b contactus-section">
           <Contact pageName="contact-us"/>
         </div> */}
        {/* api section */}


      {/*<CommonFooter />
     </div> */}
     </>
    )
  }

}


export default ContactUsPage;
