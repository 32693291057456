

export const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log(BASE_URL);
export const CMS_BASE_URL=process.env.REACT_APP_CMS_URL;
// Landing page Endpoints

// ------------------|SEND MAIL ENDPOINTS |------------------
export const SEND_MAIL_BASE_URL =`${BASE_URL}/api/Contactus`;

//-------------------------|CRM ENDPOINTS ----------------------
export const CRM_ENTRY_BASE_URL =`${CMS_BASE_URL}cms/insert_crm_request`
