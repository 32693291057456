import React from 'react';
import { Switch, Route ,Redirect} from 'react-router-dom';
import {
  HOME_PAGE,
  CONTACT_US,
  DISCLAIMER_FROM_HOME,
  PRIVACY_POLICY_PAGE,
  TERMS_PAGE,
  NOT_FOUND

} from './constants/url';


// import Login from './containers/login';

import Landing from './components/LandingPage/landing-page';
import Disclaimer from './components/Disclaimer';
import ContactUs from './components/ContactusPage/contact-us';
import PrivacyPolicy from './components/PrivacyPolicies';
import TermsAndConstions from './components/termsandcondition/Termsandconditions';
import NotFound from './components/NotFound';


export default (props) => (
  <Switch>
     <Route exact path={DISCLAIMER_FROM_HOME} component={Disclaimer} />
    <Route exact path={CONTACT_US} component={ContactUs} />
    <Route exact path={HOME_PAGE}  render={() => <Landing props={props} />} />
    <Route exact path={PRIVACY_POLICY_PAGE} component={PrivacyPolicy} />
    <Route exact path={NOT_FOUND} component={NotFound}/>
    <Route exact path={TERMS_PAGE} component={TermsAndConstions} />
    <Redirect from="*" to={NOT_FOUND}/>
    <Route />
  </Switch>
);
