import React, { PureComponent } from "react";
import { Link, withRouter } from 'react-router-dom';
import DABlogo  from "../../../../assets/Logo 7.png"
import ZLOGO from "../../../../assets/Landing page icons/Zigram Website Logo.svg";
import Background from "../../../../assets/Group-17.png"
class CommonFooter extends PureComponent {

  render() {
    return (
      <footer className="site-footer" >
      <div className="container-ps-footer"
       style={{
        backgroundImage:`url(${Background})`
      }}>
        <div className="row">
          <div className="col-xl-3 col-md-12 col-sm-12 text-justify-lg"> 
         {/* <img className="footer-logo" src={DABlogo}></img> */}
         <a href="https://www.zigram.tech/" target="_blank"><img className="Zfooter-logo" src={ZLOGO}></img></a>
          </div>
          <div className="col-xl-2 col-md-6 col-sm-12  mt-4 pmid-3">
            <div className="mx-5-mid">    
            <h5 className="footer-link-main mb-3">Navigation</h5>
            <Link className="footer-link my-2" to="/disclaimer" target="_blank">Disclaimer</Link><br/>
            {/* <Link className="footer-link my-2" to="/privacy-policy" target="_blank">Privacy Policy</Link><br/>
            <Link className="footer-link mt-2" to="/terms-and-conditions" target="_blank">Terms and Conditions</Link> */}
            </div>
         
          </div>
          <div className="col-xl-2 col-md-6 col-sm-12  mt-4 pmid-3">
            <div className="mx-5-mid">
            <h5 className="footer-link-main mb-3">Quick Links</h5>
            <a className="footer-link my-2" href="https://www.zigram.tech/" target="_blank">ZIGRAM</a><br/>
            <a className="footer-link my-2" href="https://www.amlpenalties.com/" target="_blank">AML Penalties</a><br/>
            <a className="footer-link mt-2" href="https://www.dragnetalpha.com/" target="_blank">Dragnet Alpha</a><br/>
            <a className="footer-link my-2" href="https://www.prescreening.io/" target="_blank">PreScreening.io</a><br/>
            </div>
          </div>
          <div className="col-xl-2  col-md-6 col-sm-12 mt-4 pmid-3">
            <div className="mx-5-mid">
            <h5 className="footer-link-main mb-3">Socials</h5>
            <div className="link-row d-flex mb-2">
              <a href="https://www.facebook.com/zigram.tech/" target="_blank"> <div className="icondiv mr-2"><i className="fa fa-facebook-f" aria-hidden="true"></i></div></a>
              <a href="https://twitter.com/ZigramT" target="_blank"> <div className="icondiv mr-2"><i className="fa fa-twitter" aria-hidden="true"></i></div></a>
               <a href="https://www.linkedin.com/company/zigram/" target="_blank"><div className="icondiv mr-2"><i className="fa fa-linkedin" aria-hidden="true"></i></div></a>
            </div>
            <div className="link-row  d-flex mt-2">
            <a href="https://www.instagram.com/zigram.tech/" target="_blank"><div className="icondiv mr-2"><i className="fa fa-instagram" aria-hidden="true"></i></div></a>
            <a href="https://www.youtube.com/channel/UC2oc8QlRlYmHS-OxQrrDxAQ" target="_blank"><div className="icondiv mr-2"> <i className="fa fa-youtube-play" aria-hidden="true"></i></div></a>
            <a href="mailto:contact@zigram.tech"><div className="icondiv mr-2"><i className="fa fa-envelope" aria-hidden="true"></i></div></a>
            </div>
            <div className="my-4">
            <div className="d-flex">
              {/* <div className="mr-2 mt-1"><i className="fa fa-envelope colorFFF" aria-hidden="true"></i></div> */}
              {/* <div className="contactparamail">
                <h6 className="contact-header-mail">Email Us</h6>
                </div> */}
                 {/* <div className="contactparamail">
              <a className=" d-inline-block" href="mailto:contact@prescreening.io">contact@prescreening.io</a><br/>
                <a href="mailto:contact@zigram.tech">contact@zigram.tech</a><br/>
              </div> */}
            </div>
                <div className="row my-3 nopadding">
              <div className="col-2"><i className="fa fa-envelope colorFFF mt-1" aria-hidden="true"></i></div>
             <div className="col-10">
             <div className="contactparamail">
                <h6 className="contact-header-mail">Email Us</h6>
                <a className="mt-2 d-inline-block" href="mailto:contact@zigram.tech">contact@zigram.tech</a><br/>
                <a className="mt-2 d-inline-block" href="mailto:sales@zigram.tech">sales@zigram.tech</a><br/>
                </div>
                </div>
                </div>
                <div className="row mt-4 nopadding">
              <div className="col-2"><img src={require("../../../../assets/Call Icon-23-23.svg")} style={{width:"25px",height:'25px'}}/></div>
             <div className="col-10">
             <div className="contactparamail">
                <h6 className="contact-header-mail">Singapore</h6>
                <a className="mt-1 d-inline-block" href="tel:+65 6976 2417">+65 6976 2417 </a>
                </div>
             </div>
            </div>
            </div>
            </div>
          </div>
          <div className="col-xl-3 col-md-6 col-sm-12 mt-4 pmid-3">
            <div className="mx-5-mid">
            <h5 className="footer-link-main mb-3 pl-1">Contact Info</h5>
            <div className="d-flex mb-3">
              <div className="mr-4 mt-1"><img src={require("../../../../assets/ContactSVG.svg")} style={{width:"25px",height:'25px'}}/></div>
               <div className="contactpara">
                <h6 className="contact-header">India</h6>
                <p>Cowrks, Tower A Paras Twin Towers,<br/>Golf Course Road, Sector 54,<br/>Gurugram 122011, Haryana</p>
                </div>
            </div>
            {/* <div className="d-flex my-3">
              <div className="mr-4 mt-1"><img src={require("../../../../assets/ContactSVG.svg")} style={{width:"25px",height:'25px'}}/></div>
              <div className="contactpara">
                <h6 className="contact-header">Bangalore</h6>
            </div>
            </div>
            <div className="d-flex my-3">
              <div className="mr-4 mt-1"><img src={require("../../../../assets/ContactSVG.svg")} style={{width:"25px",height:'25px'}}/></div>
              <div className=" contactpara">
                <h6 className="contact-header">Mumbai</h6>
            </div>
            </div> */}
            <div className="d-flex mtx -3">
              <div className="mr-4 mt-1"><img src={require("../../../../assets/ContactSVG.svg")} style={{width:"25px",height:'25px'}}/></div>
                 <div className=" contactpara">
                <h6 className="contact-header">Singapore</h6>
                <p>143, Cecil Street,<br/>#25-03, GB Building,<br/>Singapore 069542</p>
            </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      {/* <div className="col-12 mb-2 bgDarkBlue">
           <>
           <small className="copyright-text">
              © Copyright {new Date().getFullYear()} Data Asset Builder
            </small>
           </>
           <div>
             <Link className="footer-link" to="/privacy-policy" target="_blank">Privacy Policy</Link>
             <Link className="footer-link" to="/terms-and-conditions" target="_blank">Terms and Conditions</Link>
           </div>
        </div> */}
        <div className="row col bgDarkBlue">
           <div className="col-lg-6 text-lg-left">
           <small className="copyright-text footer-link">
              © Copyright {new Date().getFullYear()} Data Asset Builder
            </small>
           </div>
           <div className="col-lg-6 text-center text-lg-right">
             <Link className="footer-link Border" to="/privacy-policy" target="_blank">Privacy Policy</Link>
             <Link className="footer-link last" to="/terms-and-conditions" target="_blank">Terms and Conditions</Link>
           </div>
        </div>
    </footer>
    );
  }
}

export default CommonFooter;
