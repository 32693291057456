import React from 'react'
  

const NotFound = () => {
    return (
        <div className='purple-background'>
           <div className="container " id="notFound">
               <div className="text-center mt6rem mb6rem">
                   <h1 className="notFoundHeading display-1 ">404</h1>
                  <p className="notFoundSubheading">The page you are looking for could not be found, or is temporarily unavailable</p>
                  {/* <div className="d-flex justify-content-center mt-5">
                      
                  <Link className="input-button uncopyable text-color-blue border-color-blue bg-color-white  float-right backButton" to="/dashboard">
                        Back To DashBoard
                </Link>
                  </div> */}

                </div>
           </div>
        </div>
    )
}

export default NotFound