import * as React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookiesAccept: React.FunctionComponent = () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="DataAssetBuilderZone"
      style={{ background: '#2B373B' }}
      buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
      expires={150}
    >
      <span>
        This website uses cookies. By using this website you consent to our use of these cookies.
        For more information visit our{' '}
        <a
          href="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy and Cookie Policy
        </a>{' '}
        &amp;{' '}
        <a
          href="/disclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          Disclaimer
        </a>{' '}
      </span>
    </CookieConsent>
  );
};

export default CookiesAccept;
