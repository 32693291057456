import React, { PureComponent } from "react";
import "../../../../assets/style.css"
import DABlogo  from "../../../../assets/Logo 3.png"
import WidgetMenu from "../../WidgetMenu/WidgetMenu";


class CommonHeader extends PureComponent {

  constructor(props) {

    super(props);
    this.state = {
      fullName: window.sessionStorage.getItem('name'),
      accessToken:"",
      userData:{}
    };

  }
  redirectToLogin=(e)=>{
    window.location.replace(process.env.REACT_APP_REDIRECTION_URL);
  }

  render() {

    return (
      <>
      <div className="formpage main-hb header-main">
         <header>
         <nav className="navbar navbar-expand-lg  fixed-top navbar-light bg-light header-ps">
         
                  
           <div className="container-fluid header-padding">
           <a className="navbar-brand custom" href="/"><img src={DABlogo}></img></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  
  <div className="collapse navbar-collapse justify-content-end" id="navbarNav">

    <ul className="navbar-nav navbar-left menulinks" >
      <li className="nav-item">
        <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
      </li>
    { this.props.scrollContact? <li className="nav-item">
        <a className="nav-link" onClick={this.props.scrollContact}>Contact <span className="sr-only">(current)</span></a>
      </li>:""}
      {/* <li className="nav-item">
        <button className="btn btn-primary sm-primary mtm-3 login-desi" onClick={this.redirectToLogin}> Login</button>
      </li> */}
         <li className="nav-item">
        <a className=" btn btn-primary sm-primary  loginBTN" onClick={this.redirectToLogin}>Login <span className="sr-only">(current)</span></a>
      </li>
      {/* {this.props.showbtns&&
      <li className="nav-item">
        {this.props.scrollDemo?<button className="btn btn-primary sm-primary mtm-3 sm-white btn-w-135" onClick={this.props.scrollDemo}>Book a  Free Demo</button>:<button className="btn btn-primary sm-primary mtm-3 sm-white btn-w-135" onClick={this.props.scrollDemo}>Book a  Free Demo</button>}
      </li>} */}
      <li className="nav-item">
       <button className="btn btn-primary sm-primary sm-white btn-w-135" onClick={()=>this.props.openBot(true)}>Book a Free Demo</button>
      </li>
      <li className="nav-item mx-3">
        <WidgetMenu/>
      </li>
    </ul>
  
  </div>
  </div>
</nav>
         </header>
      </div>

      </>
    );
  }
}

export default CommonHeader;
