import React ,{useState}from 'react';
import { CRM_Entry} from '../../../apis/home-api';
const CustomMessage = () => {
const [data,setData]=useState({first_name:"",last_name:"",recipient_email:"",message:"",subject:"Request Demo",phone:""});
const [success, setSuccess] = useState(false);
const submitForm = (e) => {
    e.preventDefault();
    let formData = {};
    formData['name'] = data.last_name;
    formData['title'] = data.subject;
   // formData['recipient_email'] = "noreply_amlpenalties@zigram.tech,ritesh.mohan@zigram.tech";
   // formData['sender_email'] = "admin.manager@zigram.tech";

       formData['message'] = '<p>'+data.message+'</p><br/><br/>'+
                          '<p><b>User Name:</b> '+data.last_name+'</p>'+
                          '<p><b>Email:</b> '+data.recipient_email+'</p>'+
                          '<p><b>Subject:</b> '+data.subject+'</p>';


 
    CRM_Entry(data.first_name,data.last_name,data.recipient_email,data.subject,data.phone,data.message)
    .then((res)=>{
      if(res.status===200){
        setData({first_name:"",last_name:"",recipient_email:"",message:"",subject:"Request Demo",phone:""});
        setSuccess(true);
      }
    })
}
const handleChange=(e)=>{
    setData({...data,[e.target.name]:e.target.value})
}
    return (
        <div className='chatbot-container'>
        <form onSubmit={submitForm}>
        <div className='text-center mb-4x'>
           <h3 className='ChatHeading'>Contact Our Team</h3>
        </div>
            <div className="form-group d-flex">
            <input type="text" className="form-control"
                    name="first_name"
                    id="support-form-name"
                    placeholder="First Name*"
                    required
                    value={data.first_name}
                    onChange={handleChange}
                />
                <input type="text" className="form-control ml-3"
                    name="last_name"
                    id="support-form-name"
                    placeholder="Last Name*"
                    required
                    value={data.last_name}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <input type="email" className="form-control"
                    name="recipient_email"
                    id="support-form-email"
                    placeholder="Official Email*"
                    required
                    value={data.recipient_email}
                    onChange={handleChange}
                />
            </div>
            <div className='form-group'>
                <select className="form-control" id="support-form-subject"
                    name="subject"
                    onChange={handleChange}
                    value={data.subject}
                    required>
                    <option value="">Select Subject</option>
                    <option value="Data Asset Builder -Request Demo">Request For Demo</option>
                    <option value="Data Asset Builder -Book a Discovery Call">Book a Discovery Call</option>
                </select>
            </div>
            <div className="form-group">
                <input type="number" className="form-control no-arrow"
                    name="phone"
                    id="support-phone"
                    placeholder="Phone"
                    value={data.phone}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <textarea type="text" className="form-control"
                    name="message"
                    id="support-form-message"
                    placeholder="Message"
                    // required
                    value={data.message}
                    onChange={handleChange}
                />
            </div>
            <span className='requiredMSG my-2'>Fields marked with an asterisk (*) are required</span>
            {success && <div class="alert-div my-3 p-3">
                Thank you for reaching out to us. We will get back to you shortly.
            </div>}
            <button type="submit" className="contact-btn submitbtn purple-bg">Submit</button>
        </form>
    </div>
    );
};

export default CustomMessage;